@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

html,
body,
#root,
.app,
.content {
  height: calc(100vh - 64px);
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Source Sans 3", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.collapsed .content {
  width: calc(100% - 80px);
}

.content {
  transition: width 0.3s;
  overflow-x: hidden;
  /* Add other styles as needed */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

.landing-page-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.landing-page-content {
  text-align: center;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 64px);
}

.login-page .login-container {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-page .login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-page .login-container .error-message {
  color: red;
}

.login-page .login-container form {
  display: flex;
  flex-direction: column;
}

.login-page .login-container label {
  margin-bottom: 10px;
}

.login-page .login-container input {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.login-page .login-container button {
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-page .login-container .button-switch {
  background-color: #fff000;
  color: black;
}

.login-page .login-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login-page .login-container button:hover {
  background-color: #0056b3;
}

.wrap-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: normal;
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: center;
}

/* TableStyles.css */

.table-container,
.table,
.table-row,
.table-cell {
  background-color: white;
  color: black;
}

.blink-yellow {
  animation: blink-yellow-animation 1s steps(1, start) infinite;
}

.blink-red {
  animation: blink-red-animation 1s steps(1, start) infinite;
}

@keyframes blink-yellow-animation {
  0% {
    background-color: #d6b500; /* Change background color to #D6B500 */
  }
  50% {
    background-color: transparent; /* Change to transparent for dimming effect */
  }
  100% {
    background-color: #d6b500; /* Return to #D6B500 */
  }
}

@keyframes blink-red-animation {
  0% {
    background-color: red; /* Change background color to red */
  }
  50% {
    background-color: transparent; /* Change to transparent for dimming effect */
  }
  100% {
    background-color: red; /* Return to red */
  }
}

.blink.birthday {
  color: red;
  animation: blink-birthday 1s steps(5, start) infinite;
}

@keyframes blink-birthday {
  0%,
  100% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
}

.custom-toolbar {
  color: black !important; /* Force the text color to black */
}

.custom-toolbar .MuiButton-root {
  color: black !important; /* Ensure button text is black as well */
}

/* CSS for wrapping header text */
.wrap-header-text .MuiDataGrid-columnHeaderTitle {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the number of lines */
  -webkit-box-orient: vertical;
  line-height: 1.2;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox for vertical centering */
  justify-content: center; /* Align text horizontally */
  align-items: center; /* Align text vertically */
  height: 100%; /* Ensure it takes the full height of the header cell */
}

@media (min-width: 600px) {
  .flip-page > div {
    width: 1056px !important;
    height: 816px !important;
  }

  /* Target the third child div */
  .flip-page > div > div:first-child > div:first-child {
    display: none;
  }

  .flip-page > div > div:first-child > div:nth-child(3) {
    display: none;
  }

  /* Target 2nd-to-last div */
  .flip-page > div > div:nth-last-child(2) > div:first-child {
    display: none;
  }

  .flip-page > div > div:nth-last-child(2) > div:nth-child(2) {
    display: none;
  }

  .flip-page > div > div:nth-last-child(2) > div:nth-child(4) {
    display: none;
  }
}
